var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_tree_handle" },
    [
      _vm.titleShow
        ? _c(
            "el-header",
            {
              ref: "elHeader",
              staticStyle: {
                "text-align": "right",
                "font-size": "12px",
                height: "auto",
                "min-height": "46px",
                "background-color": "#ffffff",
                padding: "0 12px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "100%",
                    "line-height": "45px",
                    "border-bottom": "1px solid #cccccc",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        float: "left",
                        display: "flex",
                        "align-items": "center",
                      },
                      attrs: { span: "18" },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              _vm.treeTitle ||
                              this.$t("cip.cmn.title.typeTitle"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "text-align": "left",
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.treeTitle === ""
                                    ? _vm.$t("cip.cmn.title.typeTitle")
                                    : _vm.treeTitle
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("avue-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading",
              },
            ],
            ref: "avueTree",
            staticClass: "avueTree",
            attrs: {
              data: _vm.treeData,
              option: _vm.treeOptions,
              defaultExpandAll: _vm.defaultExpandAll,
            },
            on: {
              "node-click": _vm.treeNodeClick,
              save: _vm.saveNode,
              update: _vm.updateNode,
              rowAdd: _vm.rowAdd,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "span",
                    { staticClass: "el-tree-node__label" },
                    [
                      _c(
                        "el-tooltip",
                        { attrs: { content: node.label, placement: "bottom" } },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                " +
                                _vm._s((node || {}).label) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
            model: {
              value: _vm.treeForm,
              callback: function ($$v) {
                _vm.treeForm = $$v
              },
              expression: "treeForm",
            },
          }),
          _vm.popoverShow
            ? _c(
                "el-popover",
                {
                  attrs: { placement: "right", width: "80", trigger: "hover" },
                },
                [
                  _vm.defaultExpandAll == false && _vm.expandShow
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.openAll },
                        },
                        [_vm._v("展开全部\n        ")]
                      )
                    : _vm._e(),
                  _vm.defaultExpandAll == true && _vm.expandShow
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.openAll },
                        },
                        [_vm._v("收缩全部\n        ")]
                      )
                    : _vm._e(),
                  _vm.isInclude == false
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0", "font-size": "14px" },
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.includeDown },
                        },
                        [_vm._v("包含下级\n        ")]
                      )
                    : _vm._e(),
                  _vm.isInclude == true
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0", "font-size": "14px" },
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.includeDown },
                        },
                        [_vm._v("不包含下级\n        ")]
                      )
                    : _vm._e(),
                  _c("el-button", {
                    staticClass: "moreBtn",
                    attrs: {
                      slot: "reference",
                      type: "primary",
                      icon: "el-icon-more",
                    },
                    slot: "reference",
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }